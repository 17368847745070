import axios from 'axios';

export const checkShouldRedirectToTestPage = async (redirectTestPage, onErrorCallback) => {
	if (localStorage.getItem('token') != null) {
		axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('token');
	}

	// Call API get user
	const userRes = await axios.get('/api/profile/user-profile');
	const user = userRes.data.profile;

	// Call API get user formation
	const formationRes = await axios.get('/api/formation');
	const formations = formationRes.data.formations;

	// Call API get user tests
	const userTestsRes = await axios.get('/api/tests/user/' + user._id);
	const tests = userTestsRes.data.tests;

	if (formations.length > 0) {
		return true; // Continue normal login flow to dashboard
	}

	if (user.manager === true && tests.length === 0 && formations.length === 0) {
		onErrorCallback && onErrorCallback();
		return false;
	}
	if (user.manager === true && tests.length > 0) {
		redirectTestPage();
		return false;
	}
	if (formations.length === 0) {
		redirectTestPage();
		return false;
	}

	return true; // Continue normal login flow to dashboard
};
